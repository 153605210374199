import { jsx as m } from "react/jsx-runtime";
import { styled as e } from "@linaria/react";
import i from "clsx";
import c, { memo as f } from "react";
/* empty css            */
const n = /* @__PURE__ */ e("div")({
  name: "STypographyCopy",
  class: "STypographyCopy_s1d3qld9",
  propsAsIs: !1
}), C = c.forwardRef(function({
  mqNode: o = "div",
  mqVariant: p = "body1",
  mqColor: r = "textPrimary",
  className: y,
  children: a,
  ...s
}, t) {
  return /* @__PURE__ */ m(n, {
    ...s,
    ref: t,
    as: o,
    className: i(y, p, r),
    children: a
  });
}), S = f(C);
export {
  S as TypographyCopy
};
