import { jsx as i } from "react/jsx-runtime";
import { makeRem as p } from "@machineq/theme";
import R from "clsx";
import c, { useRef as C } from "react";
import { ButtonChildren as d } from "./index.es285.js";
import { useCombinedRefs as S } from "./index.es228.js";
/* empty css            */
const g = "SButtonCSS_s2gliwi", k = (e, n, o, t, r) => R(e, g, n, o, t, {
  loading: r
}), m = ({
  mqVariant: e,
  mqColor: n,
  mqSize: o = "default",
  className: t,
  children: r,
  loading: u = !1,
  style: f,
  disabled: l,
  setWidth: w,
  ...B
}, b) => {
  const s = C(null), a = u && s.current ? s.current.offsetWidth : void 0, h = S(b, s);
  return {
    ...B,
    disabled: l || u,
    ref: h,
    className: k(t, e, o, n, u),
    style: {
      width: a ? p(a) : void 0,
      ...f
    }
  };
}, j = c.forwardRef(function({
  type: n = "button",
  children: o,
  ...t
}, r) {
  const u = m(t, r);
  return /* @__PURE__ */ i("button", {
    type: n,
    ...u,
    ref: r,
    children: /* @__PURE__ */ i(d, {
      loading: t.loading,
      children: o
    })
  });
}), y = c.forwardRef(function({
  children: n,
  ...o
}, t) {
  const r = m(o, t);
  return /* @__PURE__ */ i("div", {
    ...r,
    ref: t,
    children: /* @__PURE__ */ i(d, {
      loading: o.loading,
      children: n
    })
  });
}), z = c.forwardRef(function({
  children: n,
  ...o
}, t) {
  const r = m(o, t);
  return /* @__PURE__ */ i("a", {
    ...r,
    ref: t,
    children: /* @__PURE__ */ i(d, {
      loading: o.loading,
      children: n
    })
  });
});
export {
  j as Button,
  y as ButtonContent,
  z as ButtonLink
};
